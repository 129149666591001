import request from '@/utils/request'

const labelsApi = {
  // get labels info
  labels: '/api/v1/rest/labels',
  findByKeyword: '/api/v1/rest/labels/search/findByKeyword',
  findByHouses_Id: '/rest/labels/search/findByHouses_Id'
}

/**
 * list
 * @param parameter { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function labels (parameter) {
  return request({
    url: labelsApi.labels + `?page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * add
 * @param parameter { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function addLabels (parameter) {
  return request({
    url: labelsApi.labels,
    method: 'post',
    data: parameter
  })
}

/**
 * edit
 * @param parameter { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editLabels (id, data) {
  return request({
    url: labelsApi.labels + `/${id}`,
    method: 'patch',
    data
  })
}

/**
 * del
 * @param parameter id
 * @returns {*}
 */
export function delLabels (id) {
  return request({
    url: labelsApi.labels + `/${id}`,
    method: 'delete'
  })
}

/**
 * search
 * @param parameter { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findLabels (parameter) {
  return request({
    url: labelsApi.findByKeyword + `?keyword=${parameter.keyword}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * search
 * @param parameter { id: '' }
 * @returns {*}
 */
export function findByIdLabels (parameter) {
  return request({
    url: labelsApi.labels + `/${parameter.id}`,
    method: 'get'
  })
}

/**
 * labels
 * @param parameter { id: '', name: '' }
 * @returns {*}
 */
export function inLabels (parameter) {
  return request({
    url: labelsApi.labels + `/${parameter.id}/${parameter.name}`,
    method: 'get'
  })
}

/**
 * findByHouses_Id
 * @param parameter { id: '', page: '', size: '', sort: '' }
 * @returns {*}
 */
export function findByHousesIdLabels (parameter) {
  return request({
    url: labelsApi.findByHouses_Id + `?houseId=${parameter.id}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * type 
 * @param parameter { type: ''}
 * @returns {*}
 */
export function findAllByType (parameter) {
  return request({
    url: labelsApi.labels + `/search/findAllByType?type=${parameter.type}`,
    method: 'get'
  })
}
